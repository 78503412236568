import { useEffect } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useAccountContext } from 'src/components/account/context'
import { useUserData } from 'src/components/contexts/UserDataContext'
import { getCookie } from 'src/utils/getCookie'
import { HAVE_WINDOW } from 'src/constants'
import Loader from 'src/components/ui/Loader'
import StoreOrderContent from 'src/components/sections/Account/StoreOrderContent'

function StoreOrder({ id }: { readonly id: string }) {
  const { isLoading } = useAccountContext()
  const { userData, handleSetUserData } = useUserData()

  useEffect(() => {
    const userDataParam = getCookie('currentUserData')

    if (!userDataParam || userDataParam === JSON.stringify(userData)) {
      return
    }

    const parsedUserData = JSON.parse(userDataParam)

    handleSetUserData(parsedUserData)
  }, [handleSetUserData, userData])

  if (!HAVE_WINDOW) {
    return null
  }

  if (document.cookie.indexOf('memberAuthToken') < 0) {
    window.location.href = 'https://secure.decathlon.com.br/login'

    return null
  }

  return (
    <>
      <GatsbySeo noindex nofollow />
      {isLoading && <Loader />}

      <div className="flex flex-col items-center justify-center py-4 mx-auto md:justify-normal md:flex-row md:items-start px-[19px] max-w-7xl font-inter md:px-md">
        <StoreOrderContent orderId={id} />
      </div>
    </>
  )
}

export default StoreOrder
